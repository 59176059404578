import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { ScrollToTop } from './components';
import AppRoutes from './routes';

import { store, persistor } from './store';

function App() {
  return (
    <ThemeConfig>
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRoutes />
          <ToastContainer />
        </PersistGate>
      </Provider>
      <ScrollToTop />
    </ThemeConfig>
  );
}

export default App;
