import {
    Stack,
    Button,
    Container,
} from '@mui/material';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import { 
    Page,
} from '../../../components';
import { toggleModal } from '../../../store/actions/funnels';
import { FunnelList } from '../../../components/lists/funnel-list/funnel-list';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';

interface Props {}

const FunnelsScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();

    return (
        <>
            <Page title='PubZap - Funis'>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <HeaderBreadcrumbs
                            heading="Funis"
                            links={[
                                {
                                    name: 'Dashboard',
                                    href: '/funnels'
                                },
                                { name: 'Funis', href: '/funnels' },
                            ]}
                            activeLast={false}
                        />
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="#"
                            startIcon={<Icon icon={plusFill} />}
                            onClick={() => dispatch(toggleModal('create', true))}
                        >
                            Adicionar Funil
                        </Button>
                    </Stack>
                    <FunnelList />
                </Container>
            </Page>
        </>
    )
}

export default FunnelsScreen;