import React, { useState } from "react";
import ReactJsonEditor from 'jsoneditor-for-react';

interface Props {
  values: any,
  onChange: (v: any) => void,
};

const JsonEditor: React.FC<Props> = ({ values, onChange }) => {
  const [json, setJson] = useState(values);

  const PropsJsonEditor = {
    onChange: (values: any) => onChange(values),
    options: () => ({
      mode: ['tree', 'view', 'form', 'code', 'text', 'preview'],
      search: false,
      language: 'pt-BR'
    }),
  }

  return <ReactJsonEditor
    values={json}
    {...PropsJsonEditor}
  />
}

export default JsonEditor;