import {
    Stack,
    Container,
} from '@mui/material';

import { 
    Page,
} from '../../../components';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';
import { MasterLink } from '../../../components/masterlink/masterlink';

interface Props {}

const MasterLinkScreen: React.FC<Props> = () => {

    return (
        <>
            <Page title='PubZap - Link Mestre'>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <HeaderBreadcrumbs
                            heading="Link Mestre"
                            links={[
                                {
                                    name: 'Dashboard',
                                    href: '/admin/masterlink'
                                },
                                {
                                    name: 'Administrador',
                                    href: '/admin/masterlink'
                                },
                                { name: 'Link Mestre', href: '/admin/masterlink' },
                            ]}
                            activeLast={false}
                        />
                    </Stack>
                    <MasterLink />
                </Container>
            </Page>
        </>
    )
}

export default MasterLinkScreen;