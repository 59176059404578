import { initialState } from '../../reducers/bots';

export const BOT_ACTIONS = {
    // CREATE A BOT
    CREATE_BOT: '[BOT] CREATE_BOT',
    CREATE_BOT_SUCCESS: '[BOT] CREATE_BOT_SUCCESS',
    CREATE_BOT_FAILED: '[BOT] CREATE_BOT_FAILED',
    
    // UPDATE A BOT
    UPDATE_BOT: '[BOT] UPDATE_BOT',
    UPDATE_BOT_SUCCESS: '[BOT] UPDATE_BOT_SUCCESS',
    UPDATE_BOT_FAILED: '[BOT] UPDATE_BOT_FAILED',

    // FETCH BOTS
    FETCH_BOTS: '[BOT] FETCH_BOTS',
    FETCH_BOTS_SUCCESS: '[BOT] FETCH_BOTS_SUCCESS',
    FETCH_BOTS_FAILED: '[BOT] FETCH_BOTS_FAILED',

    // CONNECT BOT
    CONNECT_BOT: '[BOT] CONNECT_BOT',
    CONNECT_BOT_SUCCESS: '[BOT] CONNECT_BOT_SUCCESS',
    CONNECT_BOT_FAILED: '[BOT] CONNECT_BOT_FAILED',

    // DELETE BOT

    DELETE_BOT: '[BOT] DELETE_BOT',
    DELETE_BOT_SUCCESS: '[BOT] DELETE_BOT_SUCCESS',
    DELETE_BOT_FAILED: '[BOT] DELETE_BOT_FAILED',

    //  LOGOUT BOT
    LOGOUT_BOT: '[BOT] LOGOUT_BOT',
    LOGOUT_BOT_SUCCESS: '[BOT] LOGOUT_BOT_SUCCESS',
    LOGOUT_BOT_FAILED: '[BOT]  LOGOUT_BOT_FAILED',

    // CHECK BOT STATUS
    CHECK_STATUS: '[BOT] CHECK_STATUS',
    CHECK_STATUS_SUCCESS: '[BOT] CHECK_STATUS_SUCCESS',
    CHECK_STATUS_FAILED: '[BOT] CHECK_STATUS_FAILED',

    // TOGGLE MODAL
    TOGGLE_MODAL: '[BOT] TOGGLE_MODAL',

    // SET & CLEAR STATE VALUES  
    SET_VALUE: '[BOT] SET_VALUE',
    CLEAR_VALUES: '[BOT] CLEAR_VALUES',
}

export const setValue = (payload: any) => ({
    type: BOT_ACTIONS.SET_VALUE,
    payload,
});

export type AvailableBotModals = keyof typeof initialState.modal;

export const toggleModal = (key: AvailableBotModals, v: boolean) => {
    return ({
        type: BOT_ACTIONS.TOGGLE_MODAL,
        payload: {
            [key]: v,
        },
    });
}

export const clearValues = () => ({ type: BOT_ACTIONS.CLEAR_VALUES });
export { default as createBot } from './createBot.action';
export { default as fetchBots } from './fetchBots.action';
export { default as connectBot } from './connectBot.action';
export { default as checkBotStatus } from './checkBotStatus.action';
export { default as logoutBot } from './logoutBot.action';
export { default as updateBot } from './updateBot.action';
export { default as deleteBot } from './deleteBot.action';