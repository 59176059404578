import { AnyAction } from 'redux';
import { Organization, User } from '../../../typings';
import { AUTH_ACTIONS } from '../../actions/auth';

export interface AuthState {
    token: string | null;
    user: User | null;
    organization: Organization | null;
    permissions: string | null;
    loading: {
        login: boolean,
    },
};

const initialState: AuthState = {
    token: null,
    user: null,
    organization: null,
    permissions: null,
    loading: {
        login: false,
    },
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        // LOGIN ACTIONS
        case AUTH_ACTIONS.LOGIN:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    login: true,
                }
            }
        case AUTH_ACTIONS.LOGIN_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    login: false,
                }
            }
        case AUTH_ACTIONS.LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    login: false,
                }
            }

        // DEFAULT ACTIONS
        case AUTH_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        case AUTH_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export default reducer;