import { InstanceFilters, IndexInstancesResponse } from './../../../typings/instances.types';
import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { ReduxReducers } from '../../reducers';
import { INSTANCE_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import dayjs from 'dayjs';

const fetchInstances = (
    page: number,
    perPage: number,
    filters?: InstanceFilters,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;

            dispatch({
                type: INSTANCE_ACTIONS.FETCH_INSTANCES,
            });

            let dateFormat = {};

            if (filters?.to && filters?.from) {
                dateFormat = {
                    from: dayjs(filters.from).locale('pt-BR').toISOString(),
                    to: dayjs(filters?.to).locale('pt-BR').toISOString(),
                }
            }

            const response = await api.get<
                IndexInstancesResponse, 
                AxiosResponse<IndexInstancesResponse>
            >
            (`${ROUTES.INSTANCES.INDEX}`,
                {   
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {
                        page,
                        perPage,
                        ...filters,
                        ...dateFormat,
                    },
                }
            );

            if (response.data?.data && response?.data?.errors?.length === 0) {
                const { data: instances, totalItems } = response?.data?.data;

                dispatch({
                    type: INSTANCE_ACTIONS.FETCH_INSTANCES_SUCCESS,
                    payload: {
                        instances,
                        totalItems,
                    },
                });
                
                return callback && callback(false, 'Instâncias carregadas com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: INSTANCE_ACTIONS.FETCH_INSTANCES_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])

        } catch (error: any) {
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    IndexInstancesResponse
                >).response?.data?.errors[0];
                dispatch({
                    type: INSTANCE_ACTIONS.FETCH_INSTANCES_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: INSTANCE_ACTIONS.FETCH_INSTANCES_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default fetchInstances;