import { AnyAction } from 'redux';
import { Report } from '../../../typings';

import { REPORTS_ACTIONS } from '../../actions/reports';

export interface ReportState {
    reports: Report,
    loading: {
        index: boolean,
    },
};

export const initialState: ReportState = {
    reports: {} as Report,
    loading: {
        index: false,
    },
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        // FETCH REPORTS
        case REPORTS_ACTIONS.FETCH_REPORTS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: true,
                },
            }

        case REPORTS_ACTIONS.FETCH_REPORTS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        case REPORTS_ACTIONS.FETCH_REPORTS_SUCCESS:
            return {
                ...state,
                // reports: payload,
                loading: {
                    ...state.loading,
                    index: false,
                },
            }
        // DEFAULT ACTIONS
        case REPORTS_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        case REPORTS_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
        
    }
}

export default reducer;