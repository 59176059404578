import { isString } from 'lodash';
import { Box, Typography, Link } from '@mui/material';

import MBreadcrumbs from '../@material-extend/headcrumbs';

interface Props {
    links: {
        href: string,
        name: string,
    }[],
    heading: string,
    sx?: object,
    moreLink?: string | string[],
    activeLast: boolean,
}

export default function HeaderBreadcrumbs({ activeLast, links, heading, moreLink = '' || [], sx, ...other }: Props) {
    return (
        <Box sx={{ ...sx }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography fontSize={24} gutterBottom color={'text.default'} fontWeight={600}>
                        {heading}
                    </Typography>
                    <MBreadcrumbs links={links} activeLast={activeLast} {...other} />
                </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
                {isString(moreLink) ? (
                    <Link underline='none' href={moreLink} target="_blank" variant="body2">
                        {moreLink}
                    </Link>
                ) : (
                    moreLink.map((href) => (
                        <Link underline='none' noWrap key={href} href={href} variant="body2" target="_blank" sx={{ display: 'table' }}>
                            {href}
                        </Link>
                    ))
                )}
            </Box>
        </Box>
    );
}
