import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { BOT_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { ConnectBotResponse } from '../../../typings';
import { ReduxReducers } from '../../reducers';

const connectBot = (
    botId: number,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;

            dispatch({
                type: BOT_ACTIONS.CONNECT_BOT,
            });

            const response = await api.get<ConnectBotResponse, AxiosResponse<ConnectBotResponse>>(
                `${ROUTES.BOT.CONNECT}/${botId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

            if (response.data?.data?.qrCode && response?.data?.errors?.length === 0) {
                
                const { qrCode } = response?.data?.data;

                dispatch({
                    type: BOT_ACTIONS.CONNECT_BOT_SUCCESS,
                    payload: {
                        qrCodeToRead: qrCode,
                    },
                });
                
                return callback && callback(false, 'Qr code carregado com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: BOT_ACTIONS.CONNECT_BOT_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE] || response?.data?.data?.message);
        } catch (error: any) {

            if (error.response) {
                const ERROR_CODE = (error as AxiosError<ConnectBotResponse>).response?.data?.errors[0];
                dispatch({
                    type: BOT_ACTIONS.CONNECT_BOT_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: BOT_ACTIONS.CONNECT_BOT_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default connectBot;