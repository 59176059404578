import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { fShortenNumber } from '../../../utils/formatNumber';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

interface Props {
    label: string,
    value: number,
    backgroundColor: string,
    backgroundImage: string,
    textColor: string,
    icon: any,
}

const CountCard: React.FC<Props> = ({
    label,
    backgroundColor,
    backgroundImage,
    textColor,
    value,
    icon,
}) =>  {
    return (
        <RootStyle style={{
            backgroundColor,
            color: textColor,
        }}>
            <IconWrapperStyle style={{
                color: textColor,
                backgroundImage,
            }}>
                <Icon icon={icon} width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(value)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                {label}
            </Typography>
        </RootStyle>
    );
}

export default CountCard;