import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';

import { MotionContainer, varBounceIn } from '../components/animate';
import {
    Page,
} from '../components';

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10)
}));

export default function Page404() {
    return (
        <RootStyle title="Pagina não encontrada">
            <Container>
                <MotionContainer open>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragraph>
                                Desculpe, página não encontrada
                            </Typography>
                        </motion.div>
                        <Typography sx={{ color: 'text.secondary' }}>
                            A página que vocẽ está tentando acessar não existe.
                        </Typography>

                        <motion.div variants={varBounceIn}>
                            <Box
                                component="img"
                                src="/static/illustrations/illustration_404.svg"
                                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
                            />
                        </motion.div>

                        <Button to="/" size="large" variant="contained" component={RouterLink}>
                            Voltar
                        </Button>
                    </Box>
                </MotionContainer>
            </Container>
        </RootStyle>
    );
}