import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { DefaultRes } from '../typings';

export const ENVS = {
    dev: {
        BASE_URL: 'http://localhost:3333/api/v1.0',
    },
    stg: {
        BASE_URL: '',
    },
    prd: {
        BASE_URL: 'https://api.pub.zapfacil.info/api/v1.0',
    },
};

export const ROUTES = {
    AUTH: {
        LOGIN: '/Login',
    },
    BOT: {
        INDEX: '/Bots',
        CREATE: '/Bots',
        CONNECT: '/Bots/Connect',
        STATUS: '/Bots/Status',
        LOGOUT: '/Bots/Logout',
        UPDATE: '/Bots',
        DELETE: '/Bots'
    },
    USERS: {
        INDEX: '/Users',
    },
    REPORTS: {
        INDEX: '/Reports'
    },
    FUNNELS: {
        INDEX: '/Funnels',
    },
    EVENTS: {
        INDEX: '/Events',
    },
    INSTANCES: {
        INDEX: '/Instances',
    },
};

export const api = axios.create({
    baseURL: ENVS.prd.BASE_URL,
    headers: {
        'User-Agent': 'Pubzap',
    },
});

const onRequest = (axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
    return axiosConfig;
};
  
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponseError = (error: AxiosError<DefaultRes>): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse<DefaultRes>) => {
    console.log(`${response.config.url}`);
    return response;
};
// @ts-ignore
api.interceptors.request.use(onRequest, onRequestError);
// @ts-ignore
api.interceptors.response.use(onResponse, onResponseError);
  