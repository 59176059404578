import { DefaultRes, Funnel, Organization, Lead } from "."

export type Event = {
    id: number
    uuid: string
    lead: number | null
    organization: number | null
    funnel: number | null
    funnels: Funnel
    leads: Lead
    organizations: Organization
    type: string
    data: string
    created_at: Date
}

export type EventFilters = {
    to?: string,
    from?: string,
    funnel?: string,
    type?: string,
};

export type IndexEventsResponse = DefaultRes<{
    data: Event[],
    totalItems: number,
    perPage: number,
    page: Number,
}>;

export enum EventsEnum {
    NEW_LEAD = 'NEW_LEAD',
    LEAD_STARTED_FUNNEL = 'LEAD_STARTED_FUNNEL',
    LEAD_FINISHED_FUNNEL = 'LEAD_FINISHED_FUNNEL',
    LEAD_REPLY = 'LEAD_REPLY',
    LEAD_CLICK = 'LEAD_CLICK'
}