export const EVENT_COLUMNS = [
    {
        field: 'id',
        headerName: 'id',
        width: 250,
    },
    { 
        field: 'uuid',
        headerName: 'UUID',
        width: 350,
    },
    { 
        field: 'type',
        headerName: 'Evento',
        width: 350,
    },
    { 
        field: 'lead',
        headerName: 'Lead',
        width: 250,
    },
    { 
        field: 'organization',
        headerName: 'Organização',
        width: 250,
    },
    { 
        field: 'funnel',
        headerName: 'Funil',
        width: 250,
    },
    {
        field: 'created_at',
        headerName: 'Data de criação',
        width: 250,
    },
];