import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { BOT_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { NumberxBot, VerifyBotStatusResponse } from '../../../typings';
import { ReduxReducers } from '../../reducers';

const checkBotStatus = (
    botId: number,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;
            const { bots } = getState().bots;

            dispatch({
                type: BOT_ACTIONS.CHECK_STATUS,
            });

            const response = await api.get<VerifyBotStatusResponse, AxiosResponse<VerifyBotStatusResponse>>(
                `${ROUTES.BOT.STATUS}/${botId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

            if (response?.data?.errors?.length === 0 && (response.data?.data?.state === 'connected' || response.data?.data?.phone)) {
                dispatch({
                    type: BOT_ACTIONS.CHECK_STATUS_SUCCESS,
                    payload: {
                        bots: bots?.map(b => {
                            if (b.id !== botId) {
                                return { ...b };
                            }
                            
                            return {
                                ...b,
                                active: 1,
                                number_x_bot: [{
                                    id: -1,
                                    bot: b.id,
                                    phone: response?.data?.data?.phone,
                                    created_at: response?.data?.data?.created_at,
                                } as NumberxBot].concat(b?.number_x_bot!)
                            };
                        }),
                    },
                });
                
                return callback && callback(false, 'Bot conectado com sucesso!');
            }

            return callback && callback(true);

        } catch (error: any) {

            if (error.response) {
                const ERROR_CODE = (error as AxiosError<VerifyBotStatusResponse>).response?.data?.errors[0];
                dispatch({
                    type: BOT_ACTIONS.CHECK_STATUS_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: BOT_ACTIONS.CONNECT_BOT_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default checkBotStatus;