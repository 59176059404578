import { FunnelFilters } from './../../../typings/funnel.types';
import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { ReduxReducers } from '../../reducers';
import { FUNNELS_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { IndexFunnelsResponse } from '../../../typings';
import dayjs from 'dayjs';

const fetchFunnels = (
    page: number,
    perPage: number,
    filters?: FunnelFilters,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;

            dispatch({
                type: FUNNELS_ACTIONS.FETCH_FUNNELS,
            });

            let dateFormat = {};

            if (filters?.to && filters?.from) {
                dateFormat = {
                    from: dayjs(filters.from).locale('pt-BR').toISOString(),
                    to: dayjs(filters?.to).locale('pt-BR').toISOString(),
                }
            }

            const response = await api.get<
                IndexFunnelsResponse, 
                AxiosResponse<IndexFunnelsResponse>
            >
            (`${ROUTES.FUNNELS.INDEX}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {
                        page,
                        perPage,
                        name: filters?.name,
                        active: filters?.active,
                        uuid: filters?.uuid,
                        ...dateFormat,
                    },
                }
            );

            if (response.data?.data && response?.data?.errors?.length === 0) {
                const { data: funnels, totalItems } = response?.data?.data;

                dispatch({
                    type: FUNNELS_ACTIONS.FETCH_FUNNELS_SUCCESS,
                    payload: {
                        funnels,
                        totalItems,
                    },
                });
                
                return callback && callback(false, 'Relatório carregado com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: FUNNELS_ACTIONS.FETCH_FUNNELS_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])

        } catch (error: any) {
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    IndexFunnelsResponse
                >).response?.data?.errors[0];
                dispatch({
                    type: FUNNELS_ACTIONS.FETCH_FUNNELS_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: FUNNELS_ACTIONS.FETCH_FUNNELS_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default fetchFunnels;