import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { ReduxReducers } from '../../reducers';
import { fetchUsers, USERS_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { IndexUsersResponse, User } from '../../../typings';

const createUser = (
    user: User,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;

            dispatch({
                type: USERS_ACTIONS.CREATE_USER,
            });

            const response = await api.post<
                IndexUsersResponse, 
                AxiosResponse<IndexUsersResponse>
            >
            (`${ROUTES.USERS.INDEX}`, 
                {
                    userData: {
                        ...user,
                    },
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                },
            );

            if (response.data) {
                dispatch({
                    type: USERS_ACTIONS.CREATE_USER_SUCCESS,
                });
                dispatch(
                    fetchUsers(
                        1,
                        100,
                    ),
                );
                return callback && callback(false, 'Usuário criado com sucesso.');
            }

        } catch (error: any) {
            if (error.response) {
                const ERROR_CODE = (error as AxiosError<
                    IndexUsersResponse
                >).response?.data?.errors[0];
                dispatch({
                    type: USERS_ACTIONS.CREATE_USER_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: USERS_ACTIONS.CREATE_USER_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default createUser;