export const INSTANCE_COLUMNS = [
    {
        field: 'id',
        headerName: 'id',
        width: 250,
    },
    { 
        field: 'token',
        headerName: 'Token',
        width: 350,
    },
    { 
        field: 'free',
        headerName: 'Liberado',
        width: 350,
    },
    { 
        field: 'setup',
        headerName: 'Setup',
        width: 350,
    },
    {
        field: 'created_at',
        headerName: 'Data de criação',
        width: 250,
    },
    {
        field: 'updated_at',
        headerName: 'Data última atualização',
        width: 250,
    },
];