import React, { useEffect, useState } from 'react';
import { 
    Dialog,
    useTheme,
    useMediaQuery,
    DialogContent,
    Box,
    Typography,
    DialogActions,
    DialogContentText,
    Button,
    Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CopyBlock, dracula } from "react-code-blocks";
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import { DefaultRes, Funnel } from '../../typings';
import { api } from '../../services/api';
import ERRORS from '../../constants/errors.constants';
import { useSelector } from 'react-redux';
import { ReduxReducers } from '../../store/reducers';
import { AuthState } from '../../store/reducers/auth';

interface Props {
    visible: boolean,
    funnel: Funnel,
    onHide(): void,
};

const Input = styled('input')({
    display: 'none',
});

export const ImportLeadsModal: React.FC<Props> = ({ visible, funnel, onHide }) => {
    const theme = useTheme();
    const { token } = useSelector<ReduxReducers, AuthState>(s => s.auth);

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<null | any[]>(null);
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [showCode, setShowCode] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files && e.target.files?.length > 0) {
            const fileReader = new FileReader();
            fileReader.readAsText(e?.target!?.files![0]!, "UTF-8");
            fileReader.onload = e => {
                if (e.target) {
                    let fileContent: any;

                    try {
                        fileContent = JSON.parse(e?.target?.result! as string) as [];
                    } catch (err: any) {
                        console.log(err)
                        fileContent = String(e?.target?.result).split('\n').splice(1);
                        
                        const separator = fileContent.includes(';') ? ';' : ',';

                        fileContent = fileContent.map((d: any) => {
                            const data = d.replaceAll('\r', '').replaceAll('\n', '').split(separator);
                            return {
                                name: data[0],
                                phone: data[1],
                                email: data[2]
                            }
                        });
                    }

                    if (fileContent?.length > 5000) {
                        toast(
                            'O arquivo importado possui mais de 5000 registros.',
                            {
                                autoClose: 1500,
                                type: 'error',
                            },
                        );
                        return;
                    }
                    setFile(fileContent);
                }
            };
        }
    };

    const handleImport = async () => {
        try {
            setLoading(true);

            const { data } = await api.post<
                DefaultRes<string>,
                AxiosResponse<DefaultRes<string>>
            >
            (`/Leads/import/${funnel.uuid}`, { leads: file }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            onHide();
            setLoading(false);

            if (data.errors?.length > 0) {
                toast(
                    ERRORS[data?.errors[0]],
                    {
                        type: 'error',
                        autoClose: 1500,
                    },
                );
                return;
            }

            toast(
                'Leads importados com suceso!',
                {
                    type: 'success',
                    autoClose: 1500,
                },
            );
            
        } catch (err: any) {
            setLoading(false);
            onHide();
            toast(
                JSON.stringify(err),
                {
                    type: 'error',
                    autoClose: 1500,
                },
            );
        }
    }

    useEffect(() => {
        setFile(null);
    }, [visible]);

    return (
        <Dialog
            open={visible}
            fullWidth
            fullScreen={smallScreen}
            maxWidth='sm'
            onClose={onHide}
            scroll={"paper"}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider', background: '#FAFAFA' }}>
                <Typography sx={{ mb: 2, mt: 2}} textAlign={'center'} fontSize={18} fontWeight={400} color={'#131340'}>
                    {`Importar leads em ${funnel?.name}`}
                </Typography>
            </Box>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <DialogContentText textAlign={'center'}>
                    Para importar os leads no funil é simples, basta apenas importar um arquivo no formato .json, contendo no máximo até 5.000 registros (leads).
                </DialogContentText>
                <img 
                    src="/static/illustrations/import_files.svg" 
                    width={250}
                    height={200} 
                    style={{ alignSelf: 'center'}}
                />
                <DialogContentText textAlign={'center'}>
                    Clique <Link href='#' onClick={() => setShowCode(sc => !sc)}>aqui</Link> para {showCode ? 'esconder' : 'visualizar'} a estrutura de dados do arquivo .json.
                </DialogContentText>
                {showCode &&<CopyBlock
                    text={`[
    {
        "name": "Lead 1",
        "phone": "111111111",
        "email": "lead-exemplo1@hotmail.com"
    },
    {
        "name": "Lead 2",
        "phone": "22222222",
        "email": "lead-exemplo2@hotmail.com"
    }
]`}
                    showLineNumbers
                    codeBlock
                    language="js"
                    theme={dracula}
                />}
            
                <label htmlFor="icon-button-file-select">
                    <Input accept=".json,.csv" onChange={handleChange} id="icon-button-file-select" type="file" />
                    {!file && 
                        <Button component="span" fullWidth variant='contained' sx={{ mt: 2, mb: 2 }}>
                            {'Selecionar arquivo'}
                        </Button>
                    }
                </label>

                {file &&
                    <DialogContentText textAlign={'center'} sx={{ mt: 1 }}>
                        Arquivo selecionado, agora basta clicar em "confirmar" e aguardar o upload, ou em "cancelar" caso deseje escolher outro arquivo. <br />Total de leads no arquivo: <strong>{file?.length}</strong>
                    </DialogContentText>
                }
                
            </DialogContent>
            <DialogActions sx={{ borderTop: 1, borderColor: 'divider', background: '#FAFAFA' }}>
                <Button onClick={() => {
                    if (!file) {
                        onHide();
                        return;
                    } 
                    setFile(null);
                }} color='inherit'>
                    Cancelar
                </Button>
                <LoadingButton autoFocus loading={loading} variant='contained' disabled={file == null} onClick={handleImport}>
                    Confirmar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}