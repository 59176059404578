export const AUTH_ACTIONS = {
    // LOGIN
    LOGIN: '[AUTH] LOGIN',
    LOGIN_SUCCESS: '[AUTH] LOGIN_SUCCESS',
    LOGIN_FAILED: '[AUTH] LOGIN_FAILED',
    // SET VALUE & COMMON
    SET_VALUE: '[AUTH] SET_VALUE',
    CLEAR_VALUES: '[AUTH] CLEAR_VALUES',
    LOGOUT: '[AUTH] LOGOUT',
}

export const setValue = (payload: any) => ({
    type: AUTH_ACTIONS.SET_VALUE,
    payload,
});

export const clearValues = () => ({ type: AUTH_ACTIONS.CLEAR_VALUES });
export { default as login } from './login.action';