export const DEFAULT_FUNNEL_MSGS = [
    {
      "id": 1,
      "parentId": null,
      "delay": 180,
      "type": "msg_with_buttons",
      "title": "Olá, tudo bem?",
      "description": "",
      "buttons": [
        "Sim",
        "Não"
      ],
      "rules": [],
      "children_1": [
        {
          "id": 2,
          "parentId": 1,
          "rules": [],
          "children": [],
          "skipToNextMsg": true
        }
      ],
      "children_2": [
        {
          "id": 3,
          "parentId": 1,
          "isFinished": true,
          "type": "msg",
          "urls": [
            "https://link.com.br"
          ],
          "description": "Poxaa, {first_name}... entendo, mas se você mudar de ideia, vou deixar o link aqui para você ter acesso ao cartão pensado em você!\n\nClique no link abaixo 👇\n\n{url_1}",
          "rules": [],
          "children": []
        }
      ]
    },
    {
      "id": 4,
      "parentId": null,
      "delay": 180,
      "isFinished": true,
      "type": "msg",
      "urls": [
        "https://link2.com.br"
      ],
      "description": "{first_name} que tal sair do aperto? Trocar de carro, ou até mesmo fazer aquela reforma em casa?\n\nSeria bom né? Por isso, nós da IGU Soluções Financeiras temos uma ótima oportunidade para você! Temos um Empréstimo pré aprovado para você com parcelas em até 84x e a menor taxa de juros do mercado e melhor o dinheiro cai na sua conta em até 24hrs, sem precisar pegar filas de bancos ou coisa do tipo. \n\nClique aqui para saber como solicitar de forma 100% segura.  👇 👇 👇\n\n",
      "rules": []
    }
  ]