import React, {
    useState,
    useEffect,
} from 'react';
import {
    Grid,
    Stack,
    Button,
    Container,
    Typography,
    Pagination,
    CircularProgress,
    Select,
    MenuItem,
    TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import plusFill from '@iconify/icons-eva/plus-fill';
import alertTriangle from '@iconify/icons-eva/alert-triangle-fill';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    Page,
    Bot,
    Summary,
} from '../../../components';
import { fetchBots, toggleModal } from '../../../store/actions/bots';
import { ReduxReducers } from '../../../store/reducers';
import { BotState } from '../../../store/reducers/bots';
import useInterval from '../../../hooks/useInterval';
import { api } from '../../../services/api';

interface Props {}

const BotsScreen: React.FC<Props> = () => {
    const { token } = useSelector<ReduxReducers, any>(s => s.auth);
    const dispatch = useDispatch();
    const { loading, bots, totalPages, report } = useSelector<ReduxReducers, BotState>(s => s.bots);
    const [filters, setFilters] = useState({
        name: '',
        active: -2,
    })
    const [page, setPage] = useState(0);

    const [onLoadingFreeInstances, setOnLoadingFreeInstances] = useState(false);

    type AvailableFilters = keyof typeof filters;

    const onChangeFilter = (type: AvailableFilters, v: any) => setFilters(f => ({
        ...f,
        [type]: v,
    }));

    useEffect(() => {
        dispatch(
            fetchBots(
                page + 1,
                60,
                filters.name,
                filters.active,
            ),
        );
    }, [page, filters]);

    useInterval(() => {
        dispatch(
            fetchBots(
                page + 1,
                60,
                filters.name,
                filters.active,
            ),
        );
    }, 250000);

    const freeInstances = async () => {
        setOnLoadingFreeInstances(true);
        try {
            const result = (await api.get('/Bots/FreeInstances', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })).data;

            if (result.data.result === 'ok') {
                toast(
                    result.data.countFreeInstances + ' instâncias liberadas com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );
                
                await new Promise(resolve => setTimeout(resolve, 2500));
                window.location.reload();
                setOnLoadingFreeInstances(false);
                return true;
            }
        } catch (e: any) {
        }
        setOnLoadingFreeInstances(false);
        toast(
            'Error ao tentar liberar instâncias!',
            {
                autoClose: 1500,
                type: 'error',
            }
        );
    }

    return (
        <>
            <Page title='PubZap - Bots'>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography variant="h4" gutterBottom>
                            Bots cadastrados 
                        </Typography>
                        <LoadingButton
                            variant="contained"
                            onClick={() => freeInstances()}
                            startIcon={<Icon icon={alertTriangle} />}
                            autoFocus
                            loading={onLoadingFreeInstances}
                        >
                            Liberar Instâncias
                        </LoadingButton>
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="#"
                            onClick={() => dispatch(toggleModal('form', true))}
                            startIcon={<Icon icon={plusFill} />}
                        >
                            Adicionar Bot
                        </Button>
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <TextField
                            type="text"
                            label="Procurar por nome..."
                            value={filters?.name}
                            onChange={e => onChangeFilter('name', e.currentTarget.value)}
                        />
                        <Select 
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={filters?.active}
                            onChange={e => onChangeFilter('active', Number(e.target.value))}
                        >
                            <MenuItem value={-2}>
                                Todos
                            </MenuItem>
                            <MenuItem value={1}>
                                Online
                            </MenuItem>
                            <MenuItem value={0}>
                                Conectando
                            </MenuItem>
                            <MenuItem value={-1}>
                                Offline
                            </MenuItem>
                        </Select>
                    </Stack>
                    <Grid container spacing={3} mb={2}>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Bots online'}
                                value={report?.totalBotsOnline}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Bots offline'}
                                value={report?.totalBotsOffline}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Bots conectando'}
                                value={report?.totalBotsConnecting}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Instâncias utilizadas / Total'}
                                value={report?.totalUsedInstances + '/' + (report?.totalUsedInstances + report?.totalFreeInstances)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Hot Bots'}
                                value={report?.totalBotsHot}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Cold Bots'}
                                value={report?.totalBotsCold}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Partner Bots'}
                                value={report?.totalBotsPartner}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Summary 
                                title={'Interactive Bots'}
                                value={report?.totalBotsInteractiveBots}
                            />
                        </Grid>
                    </Grid>
                    {loading?.index && 
                        <Stack mb={3} alignItems="center" justifyContent="center">
                            <CircularProgress color="inherit" />
                        </Stack>
                    }
                    <Grid container spacing={3}>
                        {bots && bots?.map(b => (
                            <Bot 
                                active={b.deleted_at === null}
                                hourlyLimit={b?.hourly_limit!}
                                hourlyLimitSup={b?.args?.hourlyLimitSup!}
                                hourlyLimitInf={b?.args?.hourlyLimitInf!}
                                secondsRetry={b?.seconds_retry!}
                                id={b?.id}
                                key={`${b?.id}-${b?.name}`}
                                name={b?.name}
                                phone={(b?.number_x_bot && b?.number_x_bot?.length > 0) ? b.number_x_bot[0]?.phone : ''}
                                status={b?.active!}
                                next_message_date={b?.next_message_date}
                                instances={b?.instances}
                                created_at={(b?.number_x_bot && b?.number_x_bot?.length > 0) ? b.number_x_bot[0]?.created_at : ''}
                                updated_at={b?.updated_at}
                                args={b?.args}
                            />
                        ))}
                    </Grid>
                    <Stack mt={3} direction={'row'} justify-content='space-between'>
                        <Pagination 
                            page={page + 1}
                            count={totalPages}
                            onChange={(e, p) => setPage(p - 1)}
                        />
                    </Stack>
                </Container>
            </Page>

        </>
    )
}

export default BotsScreen;