export const INSTANCE_ACTIONS = {
    // FETCH INSTANCES
    FETCH_INSTANCES: '[INSTANCES] FETCH_INSTANCES',
    FETCH_INSTANCES_SUCCESS: '[INSTANCES] FETCH_INSTANCES_SUCCESS',
    FETCH_INSTANCES_FAILED: '[INSTANCES] FETCH_INSTANCES_FAILED',

    // SET & CLEAR STATE VALUES  
    SET_VALUE: '[INSTANCES] SET_VALUE',
    CLEAR_VALUES: '[INSTANCES] CLEAR_VALUES',
}

export const setValue = (payload: any) => ({
    type: INSTANCE_ACTIONS.SET_VALUE,
    payload,
});

export const clearValues = () => ({ type: INSTANCE_ACTIONS.CLEAR_VALUES });

export { default as fetchInstances } from './fetchInstances.action';