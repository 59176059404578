import React, { 
    useEffect,
} from 'react';
import {
    Stack,
    Dialog,
    DialogContent,
    DialogTitle,
    Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'

import { ReduxReducers } from '../../../store/reducers';
import { BotState } from '../../../store/reducers/bots';
import { checkBotStatus, connectBot, setValue, toggleModal } from '../../../store/actions/bots';
import useInterval from '../../../hooks/useInterval';

const ConnectBot = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const { 
        modal,
        botToConnectId,
        qrCodeToRead,
        loading,
    } = useSelector<ReduxReducers, BotState>(s => s.bots);

    const IS_FULL_SCREEN = useMediaQuery(theme.breakpoints.down('md'));

    const onClose = () => {
        dispatch(setValue({
            qrCodeToRead: null,
        }));
        dispatch(toggleModal('connect', false));
    }

    useEffect(() => {
        if (!modal?.connect) return;

        dispatch(setValue({
            qrCodeToRead: null,
        }));

        dispatch(
            connectBot(botToConnectId!, (error, message) => {
                if (error) {
                    onClose();
                    toast(
                        message, 
                        {
                            autoClose: 1500,
                            type: 'error',
                        }
                    )
                    return;
                } 
                
                toast(
                    message, 
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );
            }),
        )
    }, [botToConnectId]);

    const onReconnect = () => {
        dispatch(setValue({
            qrCodeToRead: null,
        }));
        dispatch(
            connectBot(botToConnectId!, (error, message) => {
                if (error) {
                    toast(
                        message, 
                        {
                            autoClose: 1500,
                            type: 'error',
                        }
                    )
                    return;
                } 
                
                toast(
                    message, 
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );
            }),
        )
    }

    useInterval(() => {
        if (modal?.connect && qrCodeToRead && botToConnectId) {
            dispatch(checkBotStatus(botToConnectId, (err, msg) => {
                if (!err) {
                    toast(
                        msg,
                        {
                            autoClose: 1500,
                            type: 'success',
                        }
                    );
                    onClose();
                }
            }));
        }
    }, 5000);

    if (!botToConnectId) return null;
    
    return (
        <Dialog
            open={modal?.connect}
            onClose={onClose}
        >
            <DialogTitle>
                Conectar robo
            </DialogTitle>
            <DialogContent style={{ minWidth: IS_FULL_SCREEN ? '100%' : 500 }}>
                <Stack alignItems="center" justifyContent="space-between">
                    {loading?.connect && 
                        <Stack mb={3} alignItems="center" justifyContent="center">
                            <Alert severity="info">
                                Buscando qr code, aguarde um momento...
                            </Alert>
                        </Stack>
                    }
                    {!loading?.connect && !qrCodeToRead && 
                        <Stack spacing={2} mb={3}>
                            <Alert severity="error">
                                Ocorreu um erro ao tentar buscar o qr code, tente novamente.
                            </Alert>
                        </Stack>
                    }
                    {qrCodeToRead && <img alt='Qr code' src={qrCodeToRead!} />}
                    <LoadingButton
                        fullWidth
                        disabled={loading?.connect}
                        variant='contained'
                        onClick={onReconnect}
                        loading={loading?.connect}
                    >
                        Recarregar
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default ConnectBot;