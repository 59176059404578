import { initialState } from '../../reducers/users';

export const USERS_ACTIONS = {
    // CREATE USER
    CREATE_USER: '[USERS] CREATE_USER',
    CREATE_USER_SUCCESS: '[USERS] CREATE_USER_SUCCESS',
    CREATE_USER_FAILED: '[USERS] CREATE_USER_FAILED',

    // UPDATE USER
    UPDATE_USER: '[USERS] UPDATE_USER',
    UPDATE_USER_SUCCESS: '[USERS] UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILED: '[USERS] UPDATE_USER_FAILED',

    // DESTROY USER
    DESTROY_USER: '[USER] DESTROY_USER',
    DESTROY_USER_SUCCESS: '[USER] DESTROY_USER_SUCCESS',
    DESTROY_USER_FAILED: '[USER] DESTROY_USER_FAILED',

    // FETCH USERS
    FETCH_USERS: '[USERS] FETCH_USERS',
    FETCH_USERS_SUCCESS: '[USERS] FETCH_USERS_SUCCESS',
    FETCH_USERS_FAILED: '[USERS] FETCH_USERS_FAILED',

    // SET & CLEAR STATE VALUES  
    SET_VALUE: '[USER] SET_VALUE',
    CLEAR_VALUES: '[USER] CLEAR_VALUES',

    // TOGGLE MODAL
    TOGGLE_MODAL: '[USER] TOGGLE_MODAL',
};

export const setValue = (payload: any) => ({
    type: USERS_ACTIONS.SET_VALUE,
    payload,
});

export type AvailableUserModals = keyof typeof initialState.modal;

export const toggleModal = (key: AvailableUserModals, v: boolean) => {
    return ({
        type: USERS_ACTIONS.TOGGLE_MODAL,
        payload: {
            [key]: v,
        },
    });
}

export const clearValues = () => ({ type: USERS_ACTIONS.CLEAR_VALUES });
export { default as fetchUsers } from './fetchUsers.action';
export { default as destroyUser } from './destroyUser.action';
export { default as updateUser } from './updateUser.action';