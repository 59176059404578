import React, { useEffect, useState } from 'react';
import { 
    Dialog,
    useTheme,
    useMediaQuery,
    DialogContent,
    Box,
    Typography,
    DialogActions,
    Button,
    Stack,
    TextField,
    FormControlLabel,
    Switch,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import { DateTimePicker, LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';

import { api } from '../../services/api';
import { ReduxReducers } from '../../store/reducers';
import { DefaultRes, Funnel, } from '../../typings';
import { AuthState } from '../../store/reducers/auth';
import JsonEditor from '../jsoneditor';
import { AxiosResponse } from 'axios';
import { DEFAULT_FUNNEL_MSGS } from '../../constants/default.funnel.msgs.constant';
import { fetchFunnels } from '../../store/actions/funnels';

interface Props {
    visible: boolean,
    isFromEdit: boolean,
    funnel: Funnel,
    onHide(): void,
};

export const FunnelForm: React.FC<Props> = ({
    visible,
    isFromEdit,
    funnel,
    onHide,
}) => {
    const dispatch = useDispatch();
    const { token } = useSelector<ReduxReducers, AuthState>(s => s.auth);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));    

    const FORM_SCHEMA = Yup.object().shape({
        name: Yup
            .string()
            .required('Informe o nome do funil'),
        msgs: Yup.string().optional(),
        ddi: Yup.string().optional(),
        active: Yup.number(),
        typeFunnel: Yup.string().required().default('hot'),
        startDate: Yup.string().optional(),
    });

    const formik = useFormik({
        initialValues: {
            name: funnel?.name,
            msgs: isFromEdit ? funnel?.msgs : '',
            typeFunnel: isFromEdit ? funnel?.config?.typeFunnel : 'hot',
            ddi: isFromEdit ? funnel?.config?.ddi : '55',
            active: isFromEdit ? funnel?.active : 0,
            startDate: (new Date()).toISOString(),
        },
        validationSchema: FORM_SCHEMA,
        onSubmit: async () => {
            await saveFunnel();
        },
    });

    const { 
        errors,
        touched,
        values,
        handleSubmit,
        getFieldProps,
    } = formik;

    const saveFunnel = async () => {
        try {
            setLoading(true);

            let success = false;

            if (isFromEdit) {
                const result = (await api.put<
                    DefaultRes<Funnel>,
                    AxiosResponse<DefaultRes<Funnel>>
                >('/Funnels/' + funnel?.id, {
                    name: values?.name,
                    messages: JSON.parse(values?.msgs),
                    active: values?.active,
                    config: {
                        typeFunnel: String(values?.typeFunnel),
                        ddi: String(values?.ddi),
                    },
                    startDate: values.startDate,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        "Content-Type": "application/json",
                    }
                }
                )).data;

                if (result?.data?.id) {
                    success = true;
                }
            } else {
                const result = (await api.post<
                    DefaultRes<Funnel>,
                    AxiosResponse<DefaultRes<Funnel>>
                >('/Funnels/', {
                    name: values?.name,
                    messages: JSON.parse(values?.msgs),
                    active: values?.active,
                    config: {
                        typeFunnel: (values?.typeFunnel),
                        ddi: String(values?.ddi),
                    },
                    startDate: values.startDate,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        "Content-Type": "application/json",
                    }
                }
                )).data;

                if (result?.data?.id) {
                    success = true;
                }
            }
            
            if (success) {
                toast(
                    'Funil salvo com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );

                onHide();
            } else {
                toast(
                    'Error ao salvar o funil!',
                    {
                        autoClose: 1500,
                        type: 'error',
                    }
                );
            }
            
            dispatch(
                fetchFunnels(
                    1, 
                    100
                )
            );
            onHide();
        } catch (e: any) {
            toast(
                'Error ao salvar o funil!',
                {
                    autoClose: 1500,
                    type: 'error',
                }
            );
            console.log(e);
        }
        setLoading(false);
    }

    useEffect(() => {
        formik.resetForm();
        
        if (visible && funnel) {
            formik.setValues({
                name: funnel?.name,
                msgs: funnel?.msgs,
                typeFunnel: funnel?.config?.typeFunnel,
                active: funnel?.active,
                ddi: funnel?.config?.ddi || '55',
                startDate: funnel?.start_date,
            });
        }
    }, [visible]);

    return (
        <Dialog
            open={visible}
            onClose={() => {
                formik.resetForm();
                onHide();
            }}
            fullScreen={smallScreen}
            fullWidth
            maxWidth='md'
            scroll={"paper"}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider', background: '#FAFAFA' }}>
                <Typography sx={{ mb: 2, mt: 2}} textAlign={'center'} fontSize={18} fontWeight={400} color={'#131340'}>
                    {isFromEdit ? `Visualizando ${funnel?.name}` : 'Cadastrar um novo funil'}
                </Typography>
            </Box>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent style={{ width: '100%' }}>
                        <Stack  justifyContent="space-between">
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                type="text"
                                label="Nome do funil"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                type="number"
                                label="DDI do funil"
                                {...getFieldProps('ddi')}
                                error={Boolean(touched.ddi && errors.ddi)}
                                helperText={touched.ddi && errors.ddi}
                            />
                            <Stack flexDirection={'row'} sx={{ mb: 3, mt: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Switch 
                                            checked={values?.active === 1 ? true : false} 
                                            onChange={(e, c) => {
                                                formik.setFieldValue('active', c === true ? 1 : 0);
                                            }} 
                                            name="active"
                                        />
                                    }
                                    label="Funil ativo?"
                                />

                                <FormControl fullWidth size="medium" required>
                                    <InputLabel>Tipo do Funil:</InputLabel>
                                    <Select
                                        {...getFieldProps('typeFunnel')}
                                        label="Tipo do Funil:"
                                        // value={values?.typeFunnel}
                                        // onChange={(e, c) => {
                                        //     formik.setFieldValue('typeFunnel', c);
                                        // }}

                                        // error={Boolean(touched.typeBot && errors.typeBot)}
                                        // helperText={touched.hourlyLimitInf && errors.hourlyLimitInf}
                                        // {...getFieldProps('setup')}
                                    >
                                        <MenuItem key={'hot'} value={'hot'}>Hot</MenuItem>
                                        <MenuItem value={'cold'}>Cold</MenuItem>
                                        <MenuItem value={'partner'}>Partner</MenuItem>
                                        <MenuItem value={'interactive'}>Interativo</MenuItem>
                                    </Select>
                                </FormControl>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        label="Data de Início:"
                                        ampm={false}
                                        value={values?.startDate}
                                        onChange={(newValue: any) => {
                                            // formik.setFieldValue('from', newValue.toISOString());
                                            formik.setFieldValue('startDate', newValue);
                                        }}
                                        inputFormat={'dd/MM/yyyy HH:mm:ss'}
                                        renderInput={(params: any) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                                

                                {/* <FormControlLabel
                                    control={
                                        <Switch 
                                            checked={values?.coldLead} 
                                            onChange={(e, c) => {
                                                formik.setFieldValue('coldLead', c);
                                            }} 
                                            name="coldLead"
                                        />
                                    }
                                    label="Funil para leads frio?"
                                /> */}
                            </Stack>

                            <Typography fontSize={18} fontWeight={400} color={'#131340'} sx={{ mb: 2 }}>
                                Mensagens do funil:
                            </Typography>

                            <JsonEditor
                                values={values?.msgs ? JSON.parse(values?.msgs) : DEFAULT_FUNNEL_MSGS} 
                                onChange={(v) => formik.setFieldValue('msgs', JSON.stringify(v))}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ borderTop: 1, borderColor: 'divider', background: '#FAFAFA' }}>
                        <Button onClick={onHide} color='inherit' disabled={loading}>
                            Cancelar
                        </Button>
                        <LoadingButton autoFocus loading={loading} variant='contained' type="submit">
                            Confirmar
                        </LoadingButton>
                </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}