import React, { useEffect } from 'react';
import {
    Grid,
    Stack,
    Container,
    Typography,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Refresh from '@mui/icons-material/Refresh';

import { ReportState } from '../../../store/reducers/reports';
import { fetchFunnels } from '../../../store/actions/funnels';
import { FunnelState } from '../../../store/reducers/funnels';
import { ReduxReducers } from '../../../store/reducers';
import Loading from '../../../components/loading';

import { 
    Page,
    Summary,
} from '../../../components';
import { When } from 'react-if';
import NoData from '../../../components/no-data';
import { api } from '../../../services/api';
import { PaginatorFactory } from '../../../factory';

interface Props {}

const ReportScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const { loading: loadingFunnel, funnels } = useSelector<ReduxReducers, FunnelState>(s => s.funnels);

    const [funnel, setFunnel] = React.useState([] as any);
    const [initialDate, setInitialDate] = React.useState(dayjs(new Date()).subtract(1, 'day').toISOString().split('T')[0]+'T03:00:00Z');
    const [finalDate, setFinalDate] = React.useState(dayjs(new Date(currentDate.getTime() + 86400000)).toISOString().split('T')[0]+'T02:59:59Z');
    const [onlyReportLinks, setOnlyReportLinks] = React.useState(false);

    const { token } = useSelector<ReduxReducers, any>(s => s.auth);

    const [allReports, setAllReports] = React.useState([] as any);
    const [loadingAllReports, setLoadingAllReports] = React.useState(false);
    const [selectedReport, setSelectedReport] = React.useState({} as any);
    const [pagination, setPagination] = React.useState<PaginatorFactory<Omit<Event, 'id'>>>({
        page: 0,
        perPage: 5,
    });

    const [loadingReport, setLoadingReport] = React.useState(false);

    const handleChangeMultiple = (event: any) => {
      const { options } = event.target;
      const value: any = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
                value.push(options[i].value);
            }
        }

        setFunnel(value?.join(','));
    };

    const generateReport = async (funnels: string) => {
        setLoadingReport(true);
        try {
            const result = (await api.get('/Reports', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    onlyReportLinks: onlyReportLinks ? '1' : '0',
                    funnels,
                    initialDate: dayjs(initialDate).subtract(1, 'day').locale('pt-BR').toISOString(),
                    finalDate: dayjs(finalDate).locale('pt-BR').toISOString()
                },
            })).data;

            if (Array.isArray(result?.errors) && result?.errors.length === 0) {
                toast(
                    'Relatório requisitado com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );
                
                getAllReports();
                setLoadingReport(false);
                return true;
            }
        } catch (e: any) {
            setLoadingReport(false);
            toast(
                'Error ao tentar requisitar o relatório!',
                {
                    autoClose: 1500,
                    type: 'error',
                }
            );
        }
    }

    const getAllReports = async () => {
        setLoadingAllReports(true);
        try {
            const result = (await api.get('/Reports/All', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    page: pagination?.page + 1,
                    perPage: pagination?.perPage,
                },
            })).data;

            if (Array.isArray(result?.errors) && result?.errors.length === 0) {
                toast(
                    'Relatórios carregados com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );

                setAllReports(result?.data);

                setLoadingAllReports(false);
                return true;
            }
        } catch (e: any) {
        }
        setLoadingAllReports(false);
        toast(
            'Error ao tentar carregar os relatórios!',
            {
                autoClose: 1500,
                type: 'error',
            }
        );
    }

    useEffect(() => {
        dispatch(fetchFunnels(1, 100));
        getAllReports();
    }, []);

    useEffect(() => {
        getAllReports();
    }, [pagination])

    return (
        <>
            <Page title='PubZap - Relatório'>
                <Container  maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Relatório
                        </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        <FormControl sx={{ m: 1, minWidth: 220, maxWidth: 500 }}>
                            <InputLabel shrink htmlFor="select-multiple-native">
                                Funis:
                            </InputLabel>
                            <Select
                                multiple
                                native
                                onChange={handleChangeMultiple}
                                label="Native"
                                inputProps={{
                                    id: 'select-multiple-native',
                                }}
                            >
                                {!loadingFunnel.index && funnels?.map((f) =>
                                    <option key={f.uuid} value={f.id}>
                                        {f.name}
                                    </option>
                                )}
                            </Select>
                            <FormControlLabel onChange={() => setOnlyReportLinks(!onlyReportLinks)} checked={onlyReportLinks} control={<Checkbox />} label="Apenas Relatório de Links" />
                        </FormControl>
                        <Box p={3} m={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Data inicial:"
                                    ampm={false}
                                    value={initialDate}
                                    onChange={(newValue: any) => {
                                        setInitialDate(newValue.toISOString());
                                    }}
                                    inputFormat={'dd/MM/yyyy HH:mm:ss'}
                                    renderInput={(params: any) => <TextField {...params} sx={{ mr: 2 }} />}
                                />
                                <DateTimePicker
                                    label="Data final:"
                                    ampm={false}
                                    renderInput={(params: any) => <TextField {...params} sx={{ mr: 2 }} />}
                                    value={finalDate}
                                    onChange={(newValue: any) => {
                                        setFinalDate(newValue.toISOString());
                                    }}
                                    inputFormat={'dd/MM/yyyy HH:mm:ss'}
                                />
                            </LocalizationProvider>
                            <LoadingButton
                                variant="contained"
                                onClick={() => generateReport(funnel)}
                                loading={loadingReport}
                            >
                                Gerar Relatório
                            </LoadingButton>
                        </Box>
                    </Stack>

                    <Card style={{ marginBottom: 25 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow> 
                                        <TableCell><LoadingButton onClick={getAllReports}><Refresh /></LoadingButton>Id</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Funis</TableCell>
                                        <TableCell>Data Inicial</TableCell>
                                        <TableCell>Data Final</TableCell>
                                        <TableCell>Data de Criação</TableCell>
                                        <TableCell>Tempo de Geração (min)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <When condition={!loadingAllReports}>
                                    <TableBody>
                                        {allReports?.reports?.map((report: any) => {
                                            let dataReport: any = {}
                                            try {
                                                dataReport = JSON.parse(report?.data);
                                            } catch (e: any) { }

                                            let funnelsNames: any = funnels?.filter(f => (dataReport?.funnels?.split(','))?.includes(String(f.id)))
                                            funnelsNames = funnelsNames.map((f: any) => f.name).join(', ')

                                            return (
                                                <TableRow
                                                    key={report?.id}
                                                    onClick={() => {
                                                        setSelectedReport({ ...dataReport, id: report.id })
                                                    }}
                                                    selected={report.id === selectedReport.id}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {report?.id}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {dataReport?.status === 'complete' ? 'Completo' : (dataReport?.status === 'pending' ? 'Pendente' : 'Error') || '----'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {funnelsNames}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {dayjs(dataReport?.initialDate).format('DD/MM/YYYY HH:mm:ss')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {dayjs(dataReport?.finalDate).format('DD/MM/YYYY HH:mm:ss')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {dayjs(report?.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {dataReport?.runTime || '----'}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </When>
                            </Table>

                            <When condition={loadingAllReports !== true && allReports?.length < 1}>
                                <NoData 
                                    buttonText=''
                                    onClick={() => {}}
                                    title={'Nenhum relatório foi encontrado.'}
                                    description={''}
                                />
                            </When>

                            <When condition={loadingAllReports}>
                                <Loading />
                            </When>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={allReports?.total}
                            rowsPerPage={pagination?.perPage}
                            page={pagination?.page}
                            onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                            onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                        />
                    </Card>

                    <When condition={!selectedReport}>
                        <NoData 
                            buttonText=''
                            onClick={() => {}}
                            title={'Nenhum relatório foi selecionado.'}
                            description={'Aparentemente, você ainda não selecionou nenhum relatório. Selecione um relatório.'}
                        />
                    </When>

                    <When condition={selectedReport}>
                        <Grid container spacing={3} mb={2}>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    title={'Total de Leads'}
                                    isLoading={loadingAllReports}
                                    value={selectedReport.totalLeads}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    title={'Total Feito o Primeiro Contato'}
                                    isLoading={loadingAllReports}
                                    value={selectedReport.totalFirstContact}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    title={'Total de Leads na Fila'}
                                    isLoading={loadingAllReports}
                                    value={selectedReport.totalQueue}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    title={'Total de Leads Sem WhatsApp'}
                                    isLoading={loadingAllReports}
                                    value={selectedReport.totalLeadsWithoutWpp}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    title={'Total de Cliques'}
                                    isLoading={loadingAllReports}
                                    value={selectedReport.totalClicks}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    title={'Total de Cliques Únicos'}
                                    isLoading={loadingAllReports}
                                    value={selectedReport.totalUniqueClicks}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    isLoading={loadingAllReports}
                                    title={'Total de Respostas'}
                                    value={selectedReport.totalResponses}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    isLoading={loadingAllReports}
                                    title={'Total de Respostas Únicas'}
                                    value={selectedReport.totalUniqueResponses}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Summary 
                                    isLoading={loadingAllReports}
                                    title={'Tempo de envio da primeira mensagem (em minutos)'}
                                    value={selectedReport.averageFirstMessageInMinutes}
                                />
                            </Grid>
                        </Grid>
                    </When>
                                        
                    <Typography style={{ marginTop: 50 }} variant="h4" gutterBottom>
                        Relatório de Links
                    </Typography>
                    <Card style={{ marginBottom: 25 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow> 
                                        <TableCell>Link</TableCell>
                                        <TableCell>Nome do Funil</TableCell>
                                        <TableCell>Total de Mensagens Enviadas</TableCell>
                                        <TableCell>Clicks Totais</TableCell>
                                        <TableCell>Mensagem</TableCell>
                                    </TableRow>
                                </TableHead>
                                <When condition={!loadingAllReports}>
                                    <TableBody>
                                        {selectedReport?.reportLinks?.map((reportLink: any) => {
                                            return (
                                                <TableRow
                                                    key={reportLink?.link}
                                                    onClick={() => {}}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {reportLink?.link}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {reportLink?.funnelName}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {reportLink?.totalMsgsSent}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {reportLink?.totalClicks}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                            {reportLink?.message}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </When>
                            </Table>

                            <When condition={loadingAllReports !== true && allReports?.length < 1}>
                                <NoData 
                                    buttonText=''
                                    onClick={() => {}}
                                    title={'Nenhum relatório foi encontrado.'}
                                    description={''}
                                />
                            </When>

                            <When condition={loadingAllReports}>
                                <Loading />
                            </When>
                        </TableContainer>

                        {/* <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={allReports?.total}
                            rowsPerPage={pagination?.perPage}
                            page={pagination?.page}
                            onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                            onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                        /> */}
                    </Card>
                </Container>
            </Page>
        </>
    )
}

export default ReportScreen;