export const EVENT_ACTIONS = {
    // FETCH EVENTS
    FETCH_EVENTS: '[EVENTS] FETCH_EVENTS',
    FETCH_EVENTS_SUCCESS: '[EVENTS] FETCH_EVENTS_SUCCESS',
    FETCH_EVENTS_FAILED: '[EVENTS] FETCH_EVENTS_FAILED',

    // SET & CLEAR STATE VALUES  
    SET_VALUE: '[EVENTS] SET_VALUE',
    CLEAR_VALUES: '[EVENTS] CLEAR_VALUES',
}

export const setValue = (payload: any) => ({
    type: EVENT_ACTIONS.SET_VALUE,
    payload,
});

export const clearValues = () => ({ type: EVENT_ACTIONS.CLEAR_VALUES });

export { default as fetchEvents } from './fetchEvents.action';