import React, { useState, memo } from 'react';
import dayjs from 'dayjs';
import { Card, IconButton, Typography, Stack, Grid, Badge, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify'

import { ActiveStatus } from '../../../typings';
import { setValue, toggleModal, logoutBot, deleteBot } from '../../../store/actions/bots';
import AreYouSureModal from '../../are-you-sure-modal';
import { ReduxReducers } from '../../../store/reducers';
import { BotState } from '../../../store/reducers/bots';
interface Props {
    id: number,
    active: boolean,
    name: string,
    hourlyLimit: number,
    hourlyLimitSup: number,
    hourlyLimitInf: number
    secondsRetry: number,
    status: ActiveStatus,
    phone?: string,
    next_message_date?: string,
    instances?: any,
    args?: any;
    created_at?: string,
    updated_at?: string
}

const Bot: React.FC<Props> = ({
    id,
    active,
    name,
    hourlyLimit,
    hourlyLimitSup,
    hourlyLimitInf,
    secondsRetry,
    status,
    phone,
    next_message_date,
    instances,
    args,
    created_at,
    updated_at,
}) => {
    const IS_ONLINE = status === ActiveStatus?.CONNECTED;
    const IS_OFFLINE = status === ActiveStatus?.OFFLINE;

    const [areYouSure, setAreYouSure] = useState({
        deleteBot: false,
        disconectBot: false,
    });

    const { loading } = useSelector<ReduxReducers, BotState>(s => s.bots);

    const dispatch = useDispatch();
    const [menuAnchor, setMenuAnchor] = useState<any>(null);

    const isMenuOpen = () => Boolean(menuAnchor);

    const onCloseMenu = () => setMenuAnchor(null);

    const onConnect = () => {
        dispatch(
            setValue({
                botToConnectId: id,
            }),
        );
        dispatch(
            toggleModal('connect', true),
        );
    }

    const onEditClick = () => {
        dispatch(
            setValue({
                bot: {
                    id,
                    name,
                    hourlyLimit,
                    secondsRetry,
                    hourlyLimitSup,
                    hourlyLimitInf,
                    instances,
                    args,
                },
            }),
        );
        dispatch(toggleModal('form', true));
    }

    const onLogout = () => {
        dispatch(
            logoutBot(id!, (error, message) => {
                if (error) {
                    toast(
                        message, 
                        {
                            autoClose: 1500,
                            type: 'error',
                        }
                    )
                    return;
                } 
                
                toast(
                    message, 
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );
                toggleAreYouSure('disconnectBot', false);
            }),
        );
    }

    const toggleAreYouSure = (id: 'deleteBot' | 'disconnectBot', v: boolean) => setAreYouSure(a => ({ ...a, [id]: v }));

    const onDelete = () => {
        dispatch(
            deleteBot(id!, (error,message) => {
                if (error) {
                    toast(
                        message,
                        {
                            autoClose: 1500,
                            type: 'error',
                        }
                    )
                    return;
                }

                toast(
                    message,
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );

                toggleAreYouSure('deleteBot', false);
            })
        );
    }

    return (
        <>
            <Grid item xs={8} sm={6} md={3} style = {{display:active? 'grid': 'none'}}>
                <Card>
                    <Stack spacing={1} sx={{ p:2 }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <div style={{ width: '50px' }}>
                                <Typography variant="subtitle2" align="justify" noWrap={false}>
                                    {name}
                                </Typography>
                            </div>
                            <div>
                                <Badge color='primary' badgeContent={<span>WhatsApp</span>} />
                            </div>
                            
                            <div>
                                <IconButton
                                    size="small"
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={(e) => setMenuAnchor(e.currentTarget!)}
                                >
                                    <SettingsIcon color='action' />
                                </IconButton>

                                <IconButton
                                    size="small"
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={() => toggleAreYouSure('deleteBot', true)}
                                >
                                    <DeleteIcon color='action' />
                                </IconButton>
                                <Menu
                                    open={isMenuOpen()}
                                    anchorEl={menuAnchor}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={onCloseMenu}
                                >
                                    <MenuItem onClick={onEditClick}>
                                        Editar
                                    </MenuItem>
                                    <MenuItem onClick={!IS_ONLINE ? () => onConnect() : () => toggleAreYouSure('disconnectBot', true)}>
                                        {IS_ONLINE ? 'Desconectar' : 'Conectar'}
                                    </MenuItem>
                                </Menu>
                            </div>
                            
                            
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{
                                        color: IS_ONLINE ? 'success.dark' : (IS_OFFLINE ? 'error.dark' : 'warning.main'),
                                    }}
                                >
                                    {IS_ONLINE ? 'ONLINE' : (IS_OFFLINE ? 'OFFLINE' : 'CONECTANDO')}
                                </Typography>
                                
                            </Typography>
                            <Typography
                                component="span"
                                variant="caption"
                                sx={{
                                    color: 'common.black',
                                }}
                            >
                                {phone || 'Sem número'}
                            </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{
                                        color: 'common.black',
                                    }}
                                >
                                    Tipo do bot: <strong>{args?.typeBot || '-'}</strong>
                                </Typography>
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{
                                        color: 'common.black',
                                    }}
                                >
                                    Envios por hora (superior): { hourlyLimitSup || hourlyLimit }
                                </Typography>
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{
                                        color: 'common.black',
                                    }}
                                >
                                    Envios por hora (inferior): { hourlyLimitInf || hourlyLimit }
                                </Typography>
                            </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{
                                        color: 'common.black',
                                    }}
                                >
                                    Data de atualização: {dayjs(created_at).format('DD/MM/YYYY HH:mm:ss') }
                                </Typography>
                            </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{
                                        color: 'common.black',
                                    }}
                                >
                                    Última msg enviada: {dayjs(next_message_date).format('DD/MM/YYYY HH:mm:ss')}
                                </Typography>
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                <Typography
                                    component="span"
                                    variant="caption"
                                    sx={{
                                        color: 'common.black',
                                    }}
                                >
                                    Instância: { instances?.number || '-' } ({ args?.setup })
                                </Typography>
                            </Typography>
                        </Stack>

                        <Typography
                            component="span"
                            variant="caption"
                            sx={{
                                color: 'common.black',
                            }}
                        >
                            Destinado à funis com leads frio? {Boolean(args?.cold) ? 
                                <strong>Sim</strong>
                            : 
                                <strong>Não</strong>
                            }
                        </Typography>
                    </Stack>
                </Card>
            </Grid>
            <AreYouSureModal
                title="Tem certeza?"
                description="Tem certeza que deseja desconectar esse robô? A instância será perdida."
                loading={loading?.logout}
                visible={areYouSure?.disconectBot}
                onHide={() => toggleAreYouSure('disconnectBot', false)}
                onConfirm={onLogout}
            />
            <AreYouSureModal
                title="Tem certeza?"
                description="Tem certeza que deseja deletar esse robô? O robô será perdido."
                loading={loading?.destroy}
                visible={areYouSure?.deleteBot}
                onHide={() => toggleAreYouSure('deleteBot', false)}
                onConfirm={onDelete}
            />
        </>
    )
}

export default memo(Bot);